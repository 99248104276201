import { TransformedGetterTypesTable } from "@/store/modules/table/getter-style.ts";
import { TransformedMutationTypesTable } from "@/store/modules/table/mutation-style";
import { Vue, Options } from "vue-class-component";
import { ROUTE_NAMES } from "@/constants/routeNames";
import { TransformedMutationTypesUser } from "@/store/modules/user/mutation-style";
@Options({
    components: {},
    props: {
        tabSideData: Object,
    },

    watch: {
        "$store.state.table.tableData": {
            handler(newValue, oldValue) {
                const route_name = this.$route.name;
                let categories;
                switch (route_name) {
                    case ROUTE_NAMES.ADMINS:
                        // console.log(newValue);
                        this.arrayTab = [
                            {
                                name: "All",
                                role: "",
                                quantity: 0,
                                isActive: true,
                            },
                        ];
                        categories = newValue.categories;
                        for (let item in categories) {
                            const newTab = {
                                name: item,
                                role: item,
                                quantity: categories[item],
                                isActive: false,
                            };
                            this.arrayTab.push(newTab);
                            this.arrayTab[0].quantity =
                                this.arrayTab[0].quantity + categories[item];
                        }
                        break;
                    case ROUTE_NAMES.PAID_USERS:
                    case ROUTE_NAMES.USERS:
                        this.arrayTab = [
                            {
                                name: "Learner",
                                role: "learner",
                                quantity: 0,
                                isActive: true,
                            },
                            {
                                name: "Parent",
                                role: "parent",
                                quantity: 0,
                                isActive: false,
                            },
                        ];
                        categories = newValue.categories;
                        for (let item in categories) {
                            if (item === "leaner_paid") {
                                this.arrayTab[0].quantity = categories[item];
                            } else if (item === "parent_paid") {
                                this.arrayTab[1].quantity = categories[item];
                            }
                        }
                        break;

                    case ROUTE_NAMES.FREE_USERS:
                        this.arrayTab = [
                            {
                                name: "Learner",
                                role: "learner",
                                quantity: 0,
                                isActive: true,
                            },
                            {
                                name: "Parent",
                                role: "parent",
                                quantity: 0,
                                isActive: false,
                            },
                        ];
                        categories = newValue.categories;
                        for (let item in categories) {
                            if (item === "leaner_free") {
                                this.arrayTab[0].quantity = categories[item];
                            } else if (item === "parent_free") {
                                this.arrayTab[1].quantity = categories[item];
                            }
                        }
                        break;

                    case ROUTE_NAMES.SCENARIOS:
                    case ROUTE_NAMES.AI_CHARACTER:
                        this.arrayTab = [];
                        categories = newValue.categories;
                        for (let item in categories) {
                            const newTab = {
                                name: item==='entertainment' ? 'Tutor Session' :item.replace("_", " "),
                                role: item,
                                quantity: categories[item],
                                isActive: false,
                            };

                            this.arrayTab.push(newTab);
                            this.arrayTab[0].role = null;
                        }
                        break;

                    default:
                        break;
                }
            },
            deep: true,
        },
    },
})
export default class TabSide extends Vue {
    public tabActiveIndex: number = 0;
    public tabSideData!: any;
    public arrayTab: any = [];

    beforeMount(): void {
        const route_name = this.$route.name;

        switch (route_name) {
            case ROUTE_NAMES.ADMINS:
                this.arrayTab = [
                    { name: "All", role: "", quantity: 0, isActive: true },
                ];
                break;
            case ROUTE_NAMES.PAID_USERS:
            case ROUTE_NAMES.FREE_USERS:
            case ROUTE_NAMES.USERS:
                this.arrayTab = [
                    {
                        name: "Learner",
                        role: "learner",
                        quantity: 0,
                        isActive: true,
                    },
                    {
                        name: "Parent",
                        role: "parent",
                        quantity: 0,
                        isActive: false,
                    },
                ];
                break;
            case ROUTE_NAMES.AI_CHARACTER:
                this.arrayTab = [
                    { name: "All", role: "", quantity: 0, isActive: true },
                ];
                break;
            default:
                break;
        }
    }

    mounted(): void {}

    public handleChangeTab(index: any) {
        this.handleFilter(this.arrayTab[index].role);
        this.tabActiveIndex = index;
    }

    public handleFilter(tabName: string) {
        let payload = {};
        const route_name = this.$route.name;

        switch (route_name) {
            case ROUTE_NAMES.ADMINS:
                payload = {
                    role_name: tabName,
                    page: 1,
                };
                this.$store.dispatch(
                    TransformedMutationTypesTable.QUERY_TABLE_DATA,
                    payload
                );
                break;
            case ROUTE_NAMES.PAID_USERS:
            case ROUTE_NAMES.USERS:
            case ROUTE_NAMES.FREE_USERS:
                this.$store.commit(
                    TransformedMutationTypesUser.SET_TAB_ACTIVE,
                    tabName
                );
                break;
            case ROUTE_NAMES.AI_CHARACTER:
                payload = {
                    status: tabName,
                    page: 1,
                };
                this.$store.dispatch(
                    TransformedMutationTypesTable.QUERY_TABLE_DATA,
                    payload
                );
                break;
            case ROUTE_NAMES.SCENARIOS:
                payload = {
                    type: tabName,
                    page: 1,
                };
                this.$store.dispatch(
                    TransformedMutationTypesTable.QUERY_TABLE_DATA,
                    payload
                );
                break;
            default:
                break;
        }
    }
}
