//@ts-nocheck
import { RouteInterface } from "@/interfaces/route";
export const ROUTE_NAMES: RouteInterface = {
    LOGIN: "login",
    DASHBOARD: "dashboard",
    USERS: "users",
    ADMINS: "admins",
    SUBSCRIPTION: "subscription",
    PLUGIN: "plugin",
    AI_CHARACTER: "ai-character",
    SCENARIOS: "scenarios",
    MISSION: "mission",
    BLOG: "blog",
    PROFILE: "profile",
    REPORT: "report",
    ROLEPLAY_PROMPT: "roleplay-prompt",

    PAID_USERS: "users/paid-users",
    FREE_USERS: "users/free-users",
    LINK_REQUEST_USERS: "link-request-users",
    CHANGE_PASSWORD: "change-password",
    CREATE_CHARACTER: "Create character",
    CREATE_SCENARIOS: "Create scenarios"
};

export const ROUTE_PATHS: RouteInterface = (() => {
    const paths = {};
    for (let routeName in ROUTE_NAMES) {
        if (ROUTE_NAMES.hasOwnProperty(routeName)) {
            paths[routeName] = `/${ROUTE_NAMES[routeName]}`;
        }
    }
    return paths;
})();
