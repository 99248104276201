import { DataInit, IScenarioTopic, ITopic } from "@/interfaces/person-ai";
const languageInt = {
    English: "",
    German: "",
    French: "",
    Spanish: "",
    Italian: "",
    Portuguese: "",
    Polish: "",
    Hindi: "",
    Korean: "",
    Japanese: "",
};
class PersonAisConstants {
    // Need to correct order sort
    public static dataSort = [
        "id",
        "avatar_url",
        "name",
        "type",
        "category_name",
        "country",
        "education",
        "is_video_call_enabled",
        "video_call_configs",
        "video_call_type",
        "is_promoted",
        "status",
        "update_author",
        "create_author",
        "updated_date",
        "created_date",
    ];

    public static headerCell = {
        name: "Character Name",
        type: "Type",
        category_name: "Category",
        country: "Country",
        education: "Education",
        video_call_type: "Video Call Options",
        status: "Status",
        update_author: "Last Updated Person",
        create_author: "Author",
    };

    public static allowShowHeader = [
        "name",
        "type",
        "category_name",
        "country",
        "education",
        "video_call_type",
        "status",
        "update_author",
        "create_author",
    ];

    public static skills = {
        creativity: "Creativity",
        resilience: "Resilience",
        emotional_intelligence: "Emotional Intelligence",
        leadership: "Leadership",
        problem_solving: "Problem Solving",
        physical_fitness: "Physical Fitness",
    };

    public static tabsFilter = {
        all: "All",
        draft: "Draft",
        published: "Published",
    };

    public static imagesProfileAiCharacter = {
        details_image: [
            {
                name_handle_upload: "primary_detail",
                url_field_name: "image_url_primary_detail",
                title_school: "Avatar image",
            },
        ],
        avatar_image: [
            {
                name_handle_upload: "primary_avatar",
                url_field_name: "image_url_primary_avatar",
                title_school: "Detail image",
            },
        ],
    };
    public static imagesProfile = {
        details_image: [
            {
                name_handle_upload: "primary_detail",
                url_field_name: "image_url_primary_detail",
                title_school: "Elementary school",
            },
            {
                name_handle_upload: "secondary_detail",
                url_field_name: "image_url_secondary_detail",
                title_school: "Middle school",
            },
            {
                name_handle_upload: "high_detail",
                url_field_name: "image_url_high_detail",
                title_school: "High school",
            },
        ],
        avatar_image: [
            {
                name_handle_upload: "primary_avatar",
                url_field_name: "image_url_primary_avatar",
                title_school: "Elementary school",
            },
            {
                name_handle_upload: "secondary_avatar",
                url_field_name: "image_url_secondary_avatar",
                title_school: "Middle school",
            },
            {
                name_handle_upload: "high_avatar",
                url_field_name: "image_url_high_avatar",
                title_school: "High school",
            },
        ],
    };

    public static intVideoConfig = {
        chat_model: "",
        listening_video: "",
        responding_video: "",
        story_of_character: "",
        prompt: "",
        topic_ids: [],
        voices: {
            ...languageInt,
        },
    };
    public invalidMessage = {
        name: "",
        education: "",
        title: "",
        description: "",
        quote: "",
        inspired_character: "",
        welcome_messages: "",
        guideline_next_question_1: "",
        guideline_next_question_2: "",
        guideline_next_question_3: "",
        type: "",
        category: "",
        number_used: "",
        system_prompt: "",
        skills: "",
        status: "",
        image_url_primary_avatar: "",
        image_url_primary_detail: "",
        image_url_secondary_avatar: "",
        image_url_secondary_detail: "",
        image_url_high_avatar: "",
        image_url_high_detail: "",
        video_call_prompt: "",
        // video_call_type: "",
        responding_video: "",
        listening_video: "",
        video_call_video_topics: "",
        story_of_character: "",
        country: "",
    };

    public dataInit: DataInit = {
        name: "",
        education: "",
        title: {
            ...languageInt,
            // Vietnamese: ""
        },
        description: {
            ...languageInt,
            // Vietnamese: ""
        },
        quote: {
            ...languageInt,
            // Vietnamese: ""
        },
        inspired_character: "",
        welcome_messages: {
            ...languageInt,
            // Vietnamese: ""
        },
        guideline_next_question_1: {
            ...languageInt,
            // Vietnamese: ""
        },
        guideline_next_question_2: {
            ...languageInt,
            // Vietnamese: ""
        },
        guideline_next_question_3: {
            ...languageInt,
            // Vietnamese: ""
        },
        type: "chat",
        category_id: 0,
        number_used: 0,
        // is_promoted: false,
        system_prompt: "",
        skills: {
            creativity: 0,
            resilience: 0,
            emotional_intelligence: 0,
            leadership: 0,
            problem_solving: 0,
            physical_fitness: 0,
        },
        image_url_primary_avatar: null,
        image_url_primary_detail: null,
        image_url_secondary_avatar: null,
        image_url_secondary_detail: null,
        image_url_high_avatar: null,
        image_url_high_detail: null,
        // video_call_feature: "",
        is_video_call_enabled: false,
        status: "",
        video_call_configs: {
            chat_model: "",
            listening_video: "",
            responding_video: "",
            prompt: "",
            story_of_character: "",
            topic_ids: [],
            voices: {
                ...languageInt,
            },
        },
    };

    public dataInitScenarioTopic: IScenarioTopic = {
        type: "entertainment",
        is_free: true,
        prompt: "",
        image_url: null,
        gif_url: null,
        level: "intermediate",
        video_call_topic_id: null,
        story_of_character: null,
        person_ai_id: null,
        is_published: true,
        content: {
            English: null,
            German: null,
            French: null,
            Spanish: null,
            Italian: null,
            Portuguese: null,
            Polish: null,
            Hindi: null,
            Korean: null,
            Japanese: null,
        },
    };

    public dataInitTopic: ITopic = {
        name: {
            English: "",
            German: "",
            French: "",
            Spanish: "",
            Italian: "",
            Portuguese: "",
            Polish: "",
            Hindi: "",
            Korean: "",
            Japanese: "",
        },
        image_url: "",
        topic_type: "",
    };

    public languageSelected = {
        title: "English",
        description: "English",
        quote: "English",
        welcome_messages: "English",
        guideline_next_question_1: "English",
        guideline_next_question_2: "English",
        guideline_next_question_3: "English",
    };

    public static infoSystemPromptTranslate = {
        title: "title",
        description: "description",
        quote: "quote",
        welcome_messages: "welcome_messages",
        guideline_next_question_1: "guideline_next_question_1",
        guideline_next_question_2: "guideline_next_question_2",
        guideline_next_question_3: "guideline_next_question_3",
    };

    public static fieldsTranslate: { [key: string]: string } = {
        title: "Title",
        description: "Description",
        quote: "Quote",
        welcome_messages: "Welcome message for first entry",
        guideline_next_question_1:
            "Guideline next question for first entry: Question 1",
        guideline_next_question_2:
            "Guideline next question for first entry: Question 2",
        guideline_next_question_3:
            "Guideline next question for first entry: Question 3",
    };

    public static allowEditList = ["category_name", "education", "status"];
    public static getKeysHeaderCell() {
        return Object.keys(this.headerCell);
    }
    public static getValuesHeaderCell() {
        return Object.values(this.headerCell);
    }

    public static getKeysTabFilter() {
        return Object.keys(this.tabsFilter);
    }
    public static getValuesTabFilter() {
        return Object.values(this.tabsFilter);
    }

    // public static getKeysFiledTranslate() {
    //     return Object.keys(this.languageSelected)
    // }
}

export default PersonAisConstants;
