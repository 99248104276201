import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { Amplify } from "aws-amplify";
import MainLayout from "@/views/main-layout/main-layout.vue";
import LoginView from "@/views/login/login.vue";
import { ROUTE_NAMES, ROUTE_PATHS } from "@/constants/routeNames";
import store from "@/store";
import { TransformedMutationTypesTable } from "@/store/modules/table/mutation-style";
import Dashboard from "@/views/dashboard/dashboard.vue";
import CreateEditScenario from "@/components/layout/create-edit-scenario/scenario.vue";
import CreateAiCharacter from "@/components/layout/create-ai-character/create-ai-character.vue";
const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: MainLayout,
        meta: {
            requiresAuth: true,
        },

        redirect: { name: "dashboard" }, // redirect to the dashboard route by name
        children: [
            {
                path: ROUTE_NAMES.DASHBOARD,
                name: ROUTE_NAMES.DASHBOARD,
                component: Dashboard,
            },
            {
                path: ROUTE_NAMES.ADMINS,
                name: ROUTE_NAMES.ADMINS,
                component: () => import("@/views/admins/admins.vue"),
            },
            {
                path: ROUTE_NAMES.USERS,
                name: ROUTE_NAMES.USERS,
                // component: () => import('@/views/users/paid-users/paid-users.vue'),
                children: [
                    {
                        path: "paid-users",
                        name: ROUTE_NAMES.PAID_USERS,
                        component: () => import("@/views/users/users.vue"),
                        beforeEnter: async () => {
                            await store.commit(
                                TransformedMutationTypesTable.RESET_STATE
                            );
                            await store.dispatch(
                                TransformedMutationTypesTable.QUERY_TABLE_DATA,
                                { is_free: false }
                            );
                        },
                    },
                    {
                        path: "free-users",
                        name: ROUTE_NAMES.FREE_USERS,
                        component: () => import("@/views/users/users.vue"),
                        beforeEnter: async () => {
                            await store.commit(
                                TransformedMutationTypesTable.RESET_STATE
                            );
                            await store.dispatch(
                                TransformedMutationTypesTable.QUERY_TABLE_DATA,
                                { is_free: true }
                            );
                        },
                    },
                ],
            },

            {
                path: ROUTE_NAMES.SUBSCRIPTION,
                name: ROUTE_NAMES.SUBSCRIPTION,
                component: () =>
                    import("@/views/subscription/subscription.vue"),
            },
            {
                path: ROUTE_NAMES.PLUGIN,
                name: ROUTE_NAMES.PLUGIN,
                component: () => import("@/views/plugin/plugin.vue"),
            },
            {
                path: ROUTE_NAMES.AI_CHARACTER,
                name: ROUTE_NAMES.AI_CHARACTER,
                component: () =>
                    import("@/views/ai-character/ai-character.vue"),
            },
            {
                path: 'ai-character/add',
                name: ROUTE_NAMES.CREATE_CHARACTER,
                component: CreateAiCharacter,
            },
            {
                path: ROUTE_NAMES.SCENARIOS,
                name: ROUTE_NAMES.SCENARIOS,
                component: () => import("@/views/scenarios/scenarios.vue"),
            },
            {
                path: 'scenarios/add',
                name: ROUTE_NAMES.CREATE_SCENARIOS,
                component: () =>  import("@/components/layout/create-edit-scenario/scenario.vue"),
            },
            {
                path: ROUTE_NAMES.ROLEPLAY_PROMPT,
                name: ROUTE_NAMES.ROLEPLAY_PROMPT,
                component: () =>
                    import("@/views/roleplay-prompt/roleplay-prompt.vue"),
            },
            {
                path: ROUTE_NAMES.MISSION,
                name: ROUTE_NAMES.MISSION,
                component: () => import("@/views/mission/mission.vue"),
            },
            {
                path: ROUTE_NAMES.BLOG,
                name: ROUTE_NAMES.BLOG,
                component: () => import("@/views/blog/blog.vue"),
            },
            {
                path: ROUTE_NAMES.REPORT,
                name: ROUTE_NAMES.REPORT,
                component: () => import("@/views/report/report.vue"),
            },
            {
                path: ROUTE_NAMES.PROFILE,
                name: ROUTE_NAMES.PROFILE,
                component: () => import("@/views/profile/profile.vue"),
            },
            {
                path: "/test",
                name: "test",
                component: () => import("@/views/test/Test.vue"),
            },
        ],
    },
    {
        path: ROUTE_PATHS.LOGIN,
        name: ROUTE_NAMES.LOGIN,
        component: LoginView,
    },
    {
        path: ROUTE_PATHS.CHANGE_PASSWORD,
        name: ROUTE_NAMES.CHANGE_PASSWORD,
        component: () => import("@/views/change-password/change-password.vue"),
    },

    // Catch-all route
    {
        path: "/:pathMatch(.*)*", // This is a wildcard route
        redirect: "/", // Redirect to the home page
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        try {
            await Amplify.Auth.currentAuthenticatedUser();
            next();
        } catch (error) {
            next(ROUTE_PATHS.LOGIN); // Redirect to login if not authenticated
        }
    } else {
        next(); // Always call next()!
    }
});

export default router;
