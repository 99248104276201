import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/icons/png/hello.png'


const _withScopeId = n => (_pushScopeId("data-v-13e7be2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "d-flex flex-row justify-content-between align-items-center",
  style: {"width":"100%"}
}
const _hoisted_2 = {
  class: "d-flex flex-row align-items-center",
  style: {"gap":"8px"}
}
const _hoisted_3 = { style: {"color":"var(--neutral-02-day, #222)","font-size":"24px","font-style":"normal","font-weight":"600","text-transform":"capitalize"} }
const _hoisted_4 = {
  key: 0,
  class: "welcome-img",
  src: _imports_0,
  style: {"width":"32px","height":"32px","object-fit":"contain","padding":"0 0 4px 0"}
}
const _hoisted_5 = { class: "d-flex flex-row align-items-center gap-3" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.headerName), 1),
      (_ctx.routeName==='dashboard')
        ? (_openBlock(), _createElementBlock("img", _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Notification, { role: _ctx.role }, null, 8, ["role"]),
      _createElementVNode("img", {
        src: _ctx.userData?.image_url ? _ctx.userData?.image_url : require('@/assets/images/pictures/jpg/incognito-avatar.jpg'),
        style: {"width":"48px","height":"48px","border-radius":"100%","object-fit":"cover","cursor":"pointer"},
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleProfile && _ctx.handleProfile(...args)))
      }, null, 8, _hoisted_6)
    ])
  ]))
}