<script lang="ts" src="./login.ts"></script>
<style lang="scss" scoped src="./login.scss"></style>

<template>
    <div style="position: relative; background-color: #b1cdc7">
        <div
            class="background"
            style="background-color: url(images/bg.png); background-size: cover"
        >
            <div
                class="background"
                style="
                    background-image: url(images/patern.png);
                    background-size: cover;
                "
            >
                <div
                    class="modal-container"
                    style="
                        background: rgba(255, 255, 255, 0.75);
                        position: relative;
                        z-index: 90;
                    "
                >
                    <p class="login-title">Admin Panel</p>

                    <div class="content-container">
                        <div class="input-group">
                            <p class="input-label">Username</p>
                            <input
                                v-model="userInput.username"
                                @blur="
                                    userInput.username =
                                        userInput.username.trim()
                                "
                                class="input-component"
                                type="text"
                                placeholder="Enter your Username"
                            />
                        </div>

                        <div class="input-group">
                            <p class="input-label">Password</p>
                            <div style="position: relative; width: 100%">
                                <input
                                    v-model="userInput.password"
                                    @blur="
                                        userInput.password =
                                            userInput.password.trim()
                                    "
                                    class="input-component"
                                    style="width: 100%"
                                    autocomplete="null"
                                    placeholder="Enter your current password"
                                    :type="showPassword ? 'text' : 'password'"
                                    aria-label="Type old password"
                                    aria-describedby="basic-addon2"
                                />
                                <div
                                    class="d-flex justify-content-center align-items-center"
                                    style="
                                        position: absolute;
                                        right: 7px;
                                        top: 12px;
                                    "
                                >
                                    <img
                                        :src="
                                            require(`@/assets/images/icons/svg/${
                                                showPassword
                                                    ? 'eye-open.svg'
                                                    : 'eye-close.svg'
                                            }`)
                                        "
                                        width="24"
                                        height="24"
                                        style="
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            cursor: pointer;
                                        "
                                        @click="showPassword = !showPassword"
                                        alt="eye-icon"
                                    />
                                </div>
                            </div>
                        </div>

                        <button
                            :class="!userInputAllValid ? 'disabled-item' : ''"
                            class="button-solid w-100"
                            @click="handleLogin"
                        >
                            Login
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <img
            style="position: absolute; top: 0; right: 0"
            src="@/assets/images/pictures/png/leaves.png"
            alt="leaves"
        />
        <img
            style="position: absolute; bottom: 0; left: 0"
            src="@/assets/images/pictures/png/leaves-bottom.png"
            alt="leaves"
        />
        <img
            style="position: absolute; top: 0; left: 0"
            src="@/assets/images/pictures/png/ellipse-yellow.png"
            alt="ellipse-yellow"
        />
    </div>
</template>
