<script lang="ts" setup>
import { AccordingPanel, DropdownSelect } from "@/components/base";
import ApiService from "@/services/api-service";
import store from "@/store";
import ActionTypes from "@/store/action-types";
import { TransformedActionTypesPersonAi } from "@/store/modules/person-ai/action-style";
import { onMounted, ref } from "vue";
import VueMarkdown from "vue-markdown-render";
import { toast } from "vue3-toastify";

interface Props {
    prompt: string;
    enabled?: boolean;
}

const emits = defineEmits(["on-change-select", "on-return-out-come"]);
const props = withDefaults(defineProps<Props>(), {
    enabled: true,
});

const apiService = new ApiService(store);
const modelOptions = ref<any>([]);
const modelSelected = ref(null);
const loading = ref(false);
const outcome = ref("");

function onChange(value: any) {
    modelSelected.value = value;
    emits("on-change-select", value);
}

function clearOutcome() {
    outcome.value = "";
    modelSelected.value = null;
}
async function handlePreviewOutCome() {
    if (!modelSelected.value) return;

    loading.value = true;
    const res = await apiReviewOutcomeRoleplayPrompt(
        modelSelected.value,
        props.prompt
    );
    loading.value = false;
    if (res?.result) {
        outcome.value = res.result;
        emits("on-return-out-come", res.result);
    }
}

function apiReviewOutcomeRoleplayPrompt(chat_model: string, prompt: string) {
    return new Promise<any>((r) => {
        apiService.handleApiRequest(
            ActionTypes.PREVIEW_OUTCOME_ROLEPLAY_PROMPT,
            {
                chat_model,
                prompt,
            },
            (data: any) => {
                if (data?.result) r(data);
                else r(null);
            },
            () => {
                toast.error("preview outcome prompt failed!");
                r(null);
            }
        );
    });
}

function apiGetAiModel() {
    loading.value = true;
    apiService.handleApiRequest(
        TransformedActionTypesPersonAi.GET_VIDEO_CALL_CHAT_MODELS,
        {},
        (data: any) => {
            for (let item of data) {
              modelOptions.value.push({
                text: item.model,
                value: item.model,
                active: true,
                provider: item.provider,
              });
            }
            loading.value = false;
        },
        () => {
            toast.error("Get model fail!");
            loading.value = false;
        }
    );
}

onMounted(() => {
    apiGetAiModel();
});
</script>
<template>
    <div class="container-preview-outcome" :class="enabled ? '' : 'disabled'">
        <div class="control-preview-outcome">
            <DropdownSelect
                :defaultSelected="modelSelected"
                :options="modelOptions"
                :disabled="loading"
                placeholder="Choose model"
                @selectOption="onChange"
                :provider="true"
            />
            <div class="actions">
                <button
                    class="button-solid border-radius-12"
                    :disabled="!enabled || loading || !modelSelected"
                    @click="handlePreviewOutCome"
                >
                    <span
                        v-if="loading"
                        class="me-2 spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    />
                    Preview outcome
                </button>
                <button
                    class="button-outline border-radius-12 white-nowrap"
                    :disabled="!enabled || loading || !modelSelected"
                    @click="clearOutcome"
                >
                    Clear outcome
                </button>
            </div>
        </div>
        <div v-if="outcome" class="outcome-container">
            <AccordingPanel
                idTarget="preview-outcome"
                className="preview-outcome small-button box-shadow-none padding-none"
            >
                <template #title>
                    <div class="rp-sub-title">Outcome</div>
                </template>
                <template #content>
                    <VueMarkdown
                        class="outcome-content"
                        :source="outcome || ``"
                    />
                </template>
            </AccordingPanel>
        </div>
    </div>
</template>

<style lang="scss">
.container-preview-outcome {
    .control-preview-outcome {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        gap: 15px;
        .dropdown {
            max-width: 500px;
            text-align: left;
        }
        .actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }
    }
    .outcome-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        font-weight: 500;
        text-align: left;
        .rp-sub-title {
            color: var(--primary-500);
            margin-bottom: 5px;
            font-weight: 700;
            text-transform: uppercase;
        }
        .outcome-content {
            padding-left: 12px;
            white-space: pre-wrap;
            font-size: 13px;
            padding: 15px;
            border-radius: var(--radius-md);
            background-color: var(--second-01-day, #f7f8f7);
        }
    }
}
</style>
