<template>
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill="currentColor"
            d="M12.5 1.00002C12.5 0.539783 12.8731 0.166687 13.3333 0.166687C13.7936 0.166687 14.1667 0.539783 14.1667 1.00002V4.33335C14.1667 4.79359 13.7936 5.16669 13.3333 5.16669C12.8731 5.16669 12.5 4.79359 12.5 4.33335V1.00002Z"
        />
        <path
            fill="currentColor"
            d="M11.6667 1.86206C11.143 1.84235 10.588 1.83335 10 1.83335C9.41203 1.83335 8.85705 1.84235 8.33333 1.86206V4.33335C8.33333 5.25383 7.58714 6.00002 6.66667 6.00002C5.74619 6.00002 5 5.25383 5 4.33335V2.22973C2.07692 2.86895 0.756202 4.39337 0.256717 7.58413C0.180382 8.07177 0.568685 8.50002 1.06226 8.50002H18.9377C19.4313 8.50002 19.8196 8.07177 19.7433 7.58413C19.2438 4.39337 17.9231 2.86895 15 2.22973V4.33335C15 5.25383 14.2538 6.00002 13.3333 6.00002C12.4129 6.00002 11.6667 5.25383 11.6667 4.33335V1.86206Z"
        />
        <path
            fill="currentColor"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 21.8334C1.765 21.8334 0 20.0684 0 11.8334C0 11.5369 0.0022873 11.2488 0.0070814 10.9689C0.0147543 10.521 0.383226 10.1667 0.83127 10.1667H19.1687C19.6168 10.1667 19.9852 10.521 19.9929 10.9689C19.9977 11.2488 20 11.5369 20 11.8334C20 20.0684 18.235 21.8334 10 21.8334ZM4.16667 13.5C4.16667 13.0398 4.53976 12.6667 5 12.6667H7.5C7.96024 12.6667 8.33333 13.0398 8.33333 13.5C8.33333 13.9603 7.96024 14.3334 7.5 14.3334H5C4.53976 14.3334 4.16667 13.9603 4.16667 13.5ZM5 16C4.53976 16 4.16667 16.3731 4.16667 16.8334C4.16667 17.2936 4.53976 17.6667 5 17.6667H7.5C7.96024 17.6667 8.33333 17.2936 8.33333 16.8334C8.33333 16.3731 7.96024 16 7.5 16H5ZM11.6667 13.5C11.6667 13.0398 12.0398 12.6667 12.5 12.6667H15C15.4602 12.6667 15.8333 13.0398 15.8333 13.5C15.8333 13.9603 15.4602 14.3334 15 14.3334H12.5C12.0398 14.3334 11.6667 13.9603 11.6667 13.5ZM12.5 16C12.0398 16 11.6667 16.3731 11.6667 16.8334C11.6667 17.2936 12.0398 17.6667 12.5 17.6667H15C15.4602 17.6667 15.8333 17.2936 15.8333 16.8334C15.8333 16.3731 15.4602 16 15 16H12.5Z"
        />
        <path
            fill="currentColor"
            d="M5.83333 1.00002C5.83333 0.539783 6.20643 0.166687 6.66667 0.166687C7.1269 0.166687 7.5 0.539783 7.5 1.00002V4.33335C7.5 4.79359 7.1269 5.16669 6.66667 5.16669C6.20643 5.16669 5.83333 4.79359 5.83333 4.33335V1.00002Z"
        />
    </svg>
</template>
