import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-174074a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "d-flex flex-row",
  style: {"gap":"8px"}
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrayTab, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([_ctx.tabActiveIndex === index ? 'active' : '', "tab-item"]),
        key: index,
        onClick: ($event: any) => (_ctx.handleChangeTab(index))
      }, [
        _createElementVNode("p", null, _toDisplayString(item?.name) + "(" + _toDisplayString(item?.quantity) + ")", 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}