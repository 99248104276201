<script lang="ts" setup>
import { defineProps, onMounted, ref, watch } from "vue";
import { Collapse } from "bootstrap";
const props = defineProps({
    title: {
        type: String,
        required: false,
    },
    idTarget: { type: String, required: true },
    isExpanded: { type: Boolean, required: false, default: true },
    isToggleHeader: { type: Boolean, required: false, default: true },
    className: {
        type: String,
        default: "",
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isCheckboxChecked:{
      type: Boolean,
      default:false
    }
});

// const accordionCollapse = ref<Collapse | null>(null);

// onMounted(() => {
//   const collapseElement = document.getElementById(`panel-${props.idTarget}`);
//   if (collapseElement) {
//     accordionCollapse.value = new Collapse(collapseElement, {
//       toggle: false
//     });
//
//     watch(() => props.isCheckboxChecked, (newValue) => {
//       if (newValue) {
//         accordionCollapse.value?.show();
//       } else {
//         accordionCollapse.value?.hide();
//       }
//     });
//
//     watch(() => props.isExpanded, (newValue) => {
//       if (newValue) {
//         accordionCollapse.value?.show();
//       } else {
//         accordionCollapse.value?.hide();
//       }
//     });
//   }
// });

</script>
<template>
    <div
        class="accordion accordion-flush"
        :class="[className ? `custom-accordion ${className}` : '']"
    >
        <div class="accordion-item">
            <h2
                class="accordion-header"
                :class="isToggleHeader ? '' : 'un-toggle-header'"
            >
                <template v-if="isToggleHeader">
                    <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="`#panel-${idTarget}`"
                        :aria-expanded="false"
                        :disabled = "disabled"
                    >
                        <slot v-if="!title" name="title"> </slot>
                        <template v-else>
                            {{ title }}
                        </template>
                    </button>
                </template>
                <template v-else>
                    <div class="hd-title">
                        <slot v-if="!title" name="title"> </slot>
                        <template v-else>
                            {{ title }}
                        </template>
                    </div>
                    <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="`#panel-${idTarget}`"
                        :aria-expanded="false"
                    ></button>
                </template>
            </h2>

          <div
            :id="`panel-${props.idTarget}`"
            class="accordion-collapse collapse"
            :class="{ 'show': props.isExpanded || props.isCheckboxChecked}"
          >

                <div class="accordion-body">
                    <slot name="content"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.accordion {
    border-radius: 16px;
    overflow: visible;
    border: 1px solid var(--neutral-700, #ebebeb);
    background-color: var(--neutral-900, #fff);
    box-shadow: 4px 8px 32px 0px rgba(0, 0, 0, 0.08);
    width: 100%;
    font-family: Poppins;
    .accordion-header.un-toggle-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .hd-title {
            font-size: 18px;
            flex-grow: 1;
            padding: 0 0 0 15px;
        }
        .accordion-button {
            width: 60px;
        }
    }
    // padding: 8px;
    &.custom-accordion {
        padding: 0;
        background-color: transparent;
        border: none;
        .accordion-item {
            background-color: transparent;
        }
        .accordion-button {
            background-color: transparent;
        }
    }
    .accordion-button {
        box-shadow: none;

        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        color: var(--neutral-200) !important;
    }
    .accordion-button::after {
        background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 1L7 7L1 0.999998" stroke="%23200E32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
    .accordion-button:not(.collapsed)::after {
        background-image: url('data:image/svg+xml,<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 7L7 0.999999L13 7" stroke="%23200E32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
    .accordion-button:not(.collapsed) {
        background-color: transparent;
    }
    .accordion-body {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
</style>
