<script lang="ts" src="./tab-side.ts"></script>
<style scoped lang="scss" src="./tab-side.scss"></style>

<template>
  <div class="d-flex flex-row" style="gap: 8px">
    <div
      :class="tabActiveIndex === index ? 'active' : ''"
      class="tab-item"
      v-for="(item, index) in arrayTab"
      :key="index"
      @click="handleChangeTab(index)"
    >
      <p>{{ item?.name }}({{ item?.quantity }})</p>
    </div>
  </div>
</template>
