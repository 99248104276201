import ApiService from "@/services/api";
import ActionTypes from "@/store/action-types";
import { Auth } from "aws-amplify";
import { ROUTE_NAMES } from "@/constants/routeNames";
import router from "@/router";
import axios from "axios";

export default {
    [ActionTypes.AUTH_SIGN_OUT]: (
        { commit }: { commit: any },
        payload: any
    ) => {
        Auth.signOut();
        router.push("/" + ROUTE_NAMES.LOGIN);
    },
    [ActionTypes.GET_USER_DATA]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        const response = await ApiService.executeGetRequest(
            "/api/v1/admins/me",
            payload
        );
        if (response) {
            return response;
        } else {
            return null;
        }
    },
    [ActionTypes.UPDATE_USER_INFO]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        const response = await ApiService.executePutRequest(
            `/api/v1/admins/${payload.id}`,
            payload.body
        );
        if (response) {
            return response;
        } else {
            return null;
        }
    },

    [ActionTypes.UPDATE_AVATAR]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        const response = await ApiService.executePutRequest(
            `/api/v1/admins/upload-avatar/${payload.id}`,
            payload.body
        );
        if (response) {
            return response;
        } else {
            return null;
        }
    },

    [ActionTypes.GET_ALL_COUNTRY]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        try {
            const response = await axios.get(
                "https://restcountries.com/v3.1/independent?status=true&fields=name"
            );
            return response;
        } catch (error) {
            console.error("Error fetching world cities data:", error);
            throw error;
        }
    },

    [ActionTypes.GET_ROLEPLAY_PROMPT]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        try {
            const response = await ApiService.executeGetRequest(
                `/api/v2/roleplay-prompt`
            );
            return response;
        } catch (error) {
            console.error("Error get role prompt:", error);
            throw error;
        }
    },

    [ActionTypes.UPDATE_ROLEPLAY_PROMPT]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        try {
            const response = await ApiService.executePutRequest(
                `/api/v2/roleplay-prompt`,
                payload
            );
            return response;
        } catch (error) {
            console.error("Error update role prompt:", error);
            throw error;
        }
    },
    [ActionTypes.PREVIEW_OUTCOME_ROLEPLAY_PROMPT]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        try {
            const response = await ApiService.executePostRequest(
                `/api/v2/roleplay-prompt/preview-prompt`,
                payload
            );
            return response;
        } catch (error) {
            console.error("Error preview outcome prompt:", error);
            throw error;
        }
    },

    // VOCABULARY SCENARIO
    [ActionTypes.GET_VOCABULARY_SCENARIO_LANGUAGE]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        try {
            const response = await ApiService.executeGetRequest(
                `/api/v1/vocabulary-scenario-language`,
                payload
            );
            return response;
        } catch (error) {
            console.error("Error get vocabulary:", error);
            throw error;
        }
    },
    [ActionTypes.POST_VOCABULARY_SCENARIO_LANGUAGE]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        try {
            const response = await ApiService.executePostRequest(
                `/api/v1/vocabulary-scenario-language`,
                payload
            );
            return response;
        } catch (error) {
            console.error("Error save vocabulary:", error);
            throw error;
        }
    },
    [ActionTypes.PUT_VOCABULARY_SCENARIO_LANGUAGE]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        try {
            const response = await ApiService.executePutRequest(
                `/api/v1/vocabulary-scenario-language`,
                payload
            );
            return response;
        } catch (error) {
            console.error("Error update vocabulary:", error);
            throw error;
        }
    },
    [ActionTypes.GENERATE_VOCABULARY_SCENARIO_LANGUAGE]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        try {
            const response = await ApiService.executePostRequest(
                `/api/v1/vocabulary-scenario-language/auto-generate`,
                payload
            );
            return response;
        } catch (error) {
            console.error("Error auto generate vocabulary:", error);
            throw error;
        }
    },

    // Sample chat
    [ActionTypes.GET_SAMPLE_CHAT_V1]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        const  response = await ApiService.executeGetRequest(
            `/api/v1/sample-chat/${payload.scenario_id}`)
        return response ? response : null;
    },

    [ActionTypes.CREATE_SAMPLE_CHAT_V1]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        const response = await ApiService.executePostRequest(
            `/api/v1/sample-chat`,
            payload
        );
        return response ? response : null;
    },


    [ActionTypes.UPDATE_SAMPLE_CHAT_V1]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        try {
            if (!payload || !payload.scenario_id || !payload.data) {
                console.error('Payload is missing scenario_id or data fields');
                return null;
            }

            const newPayload = {
                scenario_id: payload.scenario_id,
                data: payload.data
            };

            const response = await ApiService.executePutRequest(
                `/api/v1/sample-chat`,
                newPayload
            );

            return response ? response : null;
        } catch (error) {
            console.error('Error updating sample chat:', error);
            throw error;
        }
    },

    [ActionTypes.GENERATE_SAMPLE_CHAT_V1]: async (
        { commit }: { commit: any },
        payload: any
    ) => {
        try {
            return await ApiService.executePostRequest(
                `/api/v1/sample-chat/generate-sample-chat`,
                payload
            );
        } catch (error) {
            console.error("Error auto generate sample chat:", error);
            throw error;
        }
    },
};

