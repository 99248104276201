<script lang="ts" src="./header.ts"></script>
<style scoped lang="scss" src="./header.scss"></style>


<template>
    <div class="d-flex flex-row justify-content-between align-items-center" style="width:100%">
        <div class="d-flex flex-row align-items-center" style="gap:8px">
            <p style="color: var(--neutral-02-day, #222); font-size: 24px; font-style: normal; font-weight: 600; text-transform: capitalize">{{headerName}}</p>
            <img  class="welcome-img" v-if="routeName==='dashboard'" src='@/assets/images/icons/png/hello.png' style="width:32px; height:32px; object-fit:contain; padding: 0 0 4px 0">
        </div>
        <div class="d-flex flex-row align-items-center gap-3">
            <Notification :role="role" />
            <img :src="userData?.image_url ? userData?.image_url : require('@/assets/images/pictures/jpg/incognito-avatar.jpg')" style="width:48px; height:48px; border-radius:100%; object-fit:cover; cursor:pointer" @click="handleProfile">
        </div>
    </div>
</template>

