import ApiService from "@/services/api";
import ActionTypes from "@/store/action-types";
import { Auth } from "aws-amplify";
import { ROUTE_NAMES } from '@/constants/routeNames';
import router from "@/router";
import MutationTypes from "./mutation-styles";

export default {
    
    [MutationTypes.SET_ADMIN_DATA](state: any, newValue: any) {
        state.userData = newValue
    },
    [MutationTypes.SET_ALL_COUNTRY](state: any, newValue: any) {
        state.allCountries = newValue
    },
}

