import { Vue, Options } from "vue-class-component";
import Notification from "@/components/base/notification/notification.vue";
import router from "@/router";
import { ROUTE_NAMES } from "@/constants/routeNames";
import GetterTypes from "@/store/getter-types";
@Options({
    components: {
        Notification
    },
    props: {

    },

    watch: {
        $route(to, from) {
            this.checkRouteName(to.name)
            this.routeName = to.name
        },
        '$store.state.userData': {
            handler(newValue, oldValue) {
                this.userData = newValue
                const routeName: any = router.currentRoute.value.name;
                this.checkRouteName(routeName)
            },
            deep: true
        },
    }
})
export default class Header extends Vue {
    public routeName: any = '';
    public headerName: any = '';
    public role: string = '';
    public userData: any = {}



    public beforeMount() {
        this.userData = this.$store.getters[GetterTypes.GET_USER_DATA]
        const routeName: any = router.currentRoute.value.name;
        this.checkRouteName(routeName)
    }

    public handleProfile() {
        this.$router.push('/profile');
    }

    public checkRouteName(routeName: string) {
        switch (routeName) {
            case ROUTE_NAMES.DASHBOARD:
                this.headerName = 'Welcome to Jungle station, ' + this.userData?.display_name
                break;
            case ROUTE_NAMES.USERS:
            case ROUTE_NAMES.PAID_USERS:
                this.headerName = 'Paid Users'
                break;
            case ROUTE_NAMES.FREE_USERS:
                this.headerName = 'Free Users'
                break;
            default:
                this.headerName = routeName
                break;
        }
    }
}