
class AdminsConstants {
    public static dataSort = [
        'id',
        'avatar_url',
        'username',
        'display_name',
        'email',
        'role_name',
    ];

    public static headerCell = {
        'username': 'Username',
        'display_name': 'Name',
        'email': 'Email',
        'role_name': 'Role'
    };

    public static allowShowHeader = [
        'username',
        'display_name',
        'email',
        'role_name',
    ];
    public static tabsFilter = {
        'all': 'All',
        'owner': 'Owner',
        'product': 'Product',
        'marketing': 'Marketing'
    };
    public static roles = [
        'owner',
        'product',
        'marketing',
    ];

    public static getKeysHeaderCell() {
        return Object.keys(this.headerCell)
    }
    public static getValuesHeaderCell() {
        return Object.values(this.headerCell)
    }

    public static getKeysTabFilter() {
        return Object.keys(this.tabsFilter)
    }
    public static getValuesTabFilter() {
        return Object.values(this.tabsFilter)
    }
}

export default AdminsConstants
