import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c1310cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "d-flex flex-column",
  style: {"padding":"24px 24px 24px 0","gap":"24px"}
}
const _hoisted_2 = {
  class: "",
  style: {"position":"fixed","z-index":"1051"}
}
const _hoisted_3 = {
  class: "",
  style: {"position":"fixed","z-index":"1051"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LayoutMainDashboard = _resolveComponent("LayoutMainDashboard")!
  const _component_ModalCategory = _resolveComponent("ModalCategory")!
  const _component_ModalTopicVideoCall = _resolveComponent("ModalTopicVideoCall")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LayoutMainDashboard, null, {
      "left-menu": _withCtx(() => [
        (_ctx.userData)
          ? (_openBlock(), _createBlock(_component_SideBar, {
              key: 0,
              userData: _ctx.userData
            }, null, 8, ["userData"]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Header),
          _createVNode(_component_router_view)
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ModalCategory, {
        ref: "modal-category",
        categoryDataProps: _ctx.categories
      }, null, 8, ["categoryDataProps"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ModalTopicVideoCall, { ref: "modal-topic" }, null, 512)
    ])
  ], 64))
}